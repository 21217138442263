exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-dziennik-budowy-tsx": () => import("./../../../src/pages/dziennik-budowy.tsx" /* webpackChunkName: "component---src-pages-dziennik-budowy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inwestycje-w-przygotowaniu-tsx": () => import("./../../../src/pages/inwestycje-w-przygotowaniu.tsx" /* webpackChunkName: "component---src-pages-inwestycje-w-przygotowaniu-tsx" */),
  "component---src-pages-inwestycje-zrealizowane-tsx": () => import("./../../../src/pages/inwestycje-zrealizowane.tsx" /* webpackChunkName: "component---src-pages-inwestycje-zrealizowane-tsx" */),
  "component---src-pages-kontakt-biura-sprzedazy-tsx": () => import("./../../../src/pages/kontakt/biura-sprzedazy.tsx" /* webpackChunkName: "component---src-pages-kontakt-biura-sprzedazy-tsx" */),
  "component---src-pages-kontakt-index-tsx": () => import("./../../../src/pages/kontakt/index.tsx" /* webpackChunkName: "component---src-pages-kontakt-index-tsx" */),
  "component---src-pages-kontakt-obsluga-klienta-tsx": () => import("./../../../src/pages/kontakt/obsluga-klienta.tsx" /* webpackChunkName: "component---src-pages-kontakt-obsluga-klienta-tsx" */),
  "component---src-pages-kontakt-pytania-tsx": () => import("./../../../src/pages/kontakt/pytania.tsx" /* webpackChunkName: "component---src-pages-kontakt-pytania-tsx" */),
  "component---src-pages-kontakt-serwis-techniczny-tsx": () => import("./../../../src/pages/kontakt/serwis-techniczny.tsx" /* webpackChunkName: "component---src-pages-kontakt-serwis-techniczny-tsx" */),
  "component---src-pages-kontakt-siedziba-firmy-tsx": () => import("./../../../src/pages/kontakt/siedziba-firmy.tsx" /* webpackChunkName: "component---src-pages-kontakt-siedziba-firmy-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-oferta-mieszkan-tsx": () => import("./../../../src/pages/oferta-mieszkan.tsx" /* webpackChunkName: "component---src-pages-oferta-mieszkan-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-promocje-tsx": () => import("./../../../src/pages/promocje.tsx" /* webpackChunkName: "component---src-pages-promocje-tsx" */),
  "component---src-pages-ulubione-tsx": () => import("./../../../src/pages/ulubione.tsx" /* webpackChunkName: "component---src-pages-ulubione-tsx" */),
  "component---src-pages-wspieramy-sport-tsx": () => import("./../../../src/pages/wspieramy-sport.tsx" /* webpackChunkName: "component---src-pages-wspieramy-sport-tsx" */),
  "component---src-pages-znajdz-mieszkanie-tsx": () => import("./../../../src/pages/znajdz-mieszkanie.tsx" /* webpackChunkName: "component---src-pages-znajdz-mieszkanie-tsx" */),
  "component---src-templates-flat-tsx": () => import("./../../../src/templates/Flat.tsx" /* webpackChunkName: "component---src-templates-flat-tsx" */),
  "component---src-templates-investment-simple-tsx": () => import("./../../../src/templates/InvestmentSimple.tsx" /* webpackChunkName: "component---src-templates-investment-simple-tsx" */),
  "component---src-templates-investment-tsx": () => import("./../../../src/templates/Investment.tsx" /* webpackChunkName: "component---src-templates-investment-tsx" */),
  "component---src-templates-log-building-tsx": () => import("./../../../src/templates/LogBuilding.tsx" /* webpackChunkName: "component---src-templates-log-building-tsx" */)
}

