import React, { createContext, useState } from 'react'

import type { Flat } from 'types/flat'

type State = {
  flat: Flat | null
  investment: string | null
  city: string | null
}

type NavigationContextValue = State & {
  updateState: (state: State) => void
}

const DEFAULT_STATE: State = {
  investment: null,
  flat: null,
  city: null,
}

const NavigationContext = createContext<NavigationContextValue>({
  ...DEFAULT_STATE,
  updateState: () => null,
})

export const NavigationProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<State>(DEFAULT_STATE)

  const updateState = (newState: Partial<State>) => {
    setState({ ...state, ...newState })
  }

  return (
    <NavigationContext.Provider
      value={{
        ...state,
        updateState,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

export default NavigationContext
