import type { DefaultTheme } from 'styled-components'
import type {
  Breakpoints,
  Colors,
  Container,
  Fonts,
  Grid,
  Navigation,
} from 'types/theme'

import media from 'styles/media'

export const breakpoints: Breakpoints = {
  xs: 440,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
}

export const colors: Colors = {
  primary: '#ff7811',
  primary100: '#FFE1CB',
  secondary: '#F7F4F1',
  tertiary: '#EAE3DB',
  error: '#e74c3c',
  success: '#2ecc71',
  light: '#ACACAC',
  dark: '#2B2926',
  text: '#000000',
  white: '#FFFFFF',
  white50: '#F5F5F5',
  white100: '#E1E1E1',
  black: '#000000',
  gray: '#888888',
  gray100: '#707070',
  darkgray: '#444240',
  lightgray: '#F8F8F8',
  available: ' #3ba440',
  reserved: '#fcb21f',
  sold: '#e81d25',
}

export const fonts: Fonts = {
  size: '16px',
  primary: `Poppins, Inter, --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
  secondary: `Inter, Poppins, --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
}

export const grid: Grid = {
  gap: '1rem',
}

export const navigation: Navigation = {
  desktop: '90px',
  mobile: '60px',
}

export const container: Container = {
  widthNav: '1920px',

  widthXL: '1760px',
  width: '1200px',

  widthSlimXL: '1620px',
  widthSlim: '1100px',

  widthNarrowXL: '1300px',
  widthNarrow: '900px',

  paddingMD: '2rem',
  padding: '1.35rem',
}

const theme: DefaultTheme = {
  breakpoints,
  colors,
  fonts,
  grid,
  container,
  navigation,
  media: media(),
}

export default theme
