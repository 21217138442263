const React = require('react')
const { RootWrapper } = require('templates/Root')

exports.wrapPageElement = ({ element }) => <RootWrapper>{element}</RootWrapper>

exports.onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }
}
