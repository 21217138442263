import React from 'react'
import { ThemeProvider } from 'styled-components'

import { FavouritesProvider } from 'contexts/FavouritesContext'
import { NavigationProvider } from 'contexts/NavigationContext'

import theme from 'styles/theme'
import GlobalStyles from 'styles/global'

import 'swiper/css'

export const RootWrapper: React.FC = ({ children }) => (
  <FavouritesProvider>
    <NavigationProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </NavigationProvider>
  </FavouritesProvider>
)
