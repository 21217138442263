import React, { createContext, useState, useEffect } from 'react'

import type { Flat } from 'types/flat'

type FavouritesContextProps = {
  favourites: Flat[]
  wishlistLimitExceeded: boolean
  checkIsFavourite: (flat: Flat) => boolean
  toggleFavourite: (flat: Flat) => void
}

export const WISHLIST_LIMIT = 20
const STORAGE_KEY = `FAVOURITES-ARTBUD`

const FavouritesContext = createContext<FavouritesContextProps>({
  favourites: [],
  wishlistLimitExceeded: false,
  checkIsFavourite: () => false,
  toggleFavourite: () => null,
})

export const FavouritesProvider: React.FC = ({ children }) => {
  const [favourites, setFavourites] = useState<Flat[]>([])

  const wishlistLimitExceeded = favourites.length >= WISHLIST_LIMIT

  const updateFavourites = (newFavs: Flat[]) => {
    if (newFavs.length > WISHLIST_LIMIT) return

    localStorage.setItem(STORAGE_KEY, JSON.stringify(newFavs))
    setFavourites(newFavs)
  }

  const checkIsFavourite = (flat: Flat) => {
    if (favourites.length !== 0) {
      return favourites.some(({ id }) => flat.id === id)
    }
    return false
  }

  const toggleFavourite = (flat: Flat) => {
    if (checkIsFavourite(flat)) {
      updateFavourites(favourites.filter(({ id }) => flat.id !== id))
      return
    }
    updateFavourites([...favourites, flat])
  }

  useEffect(() => {
    const storageFavs = localStorage.getItem(STORAGE_KEY)
    const savedFavs: Flat[] = storageFavs ? JSON.parse(storageFavs) : []

    setFavourites(savedFavs)
  }, [])

  return (
    <FavouritesContext.Provider
      value={{
        favourites,
        wishlistLimitExceeded,
        checkIsFavourite,
        toggleFavourite,
      }}
    >
      {children}
    </FavouritesContext.Provider>
  )
}

export default FavouritesContext
